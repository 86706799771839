export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      roles: [
        'admin',
        'creator',
        'editor',
      ],
      users: [],
    };
  },

  actions: {
    // User
    async fetchUsers(params) {
      this.users = [];

      const response = await useApi.get('/users', {
        params,
      }).catch(() => {
        useToast.error(useT('common.errorLoadingData'));
      });

      if (response) {
        this.users = response.results;
      }

      return response;
    },

    async changeUserRole({ role, user }) {
      const response = await useApi.put(`/users/${user._id}`, {
        body: {
          role,
        },
      }).catch(() => {
        useToast.error(useT('user.role.error'));
      });

      if (response?.role) {
        _find(this.users, { id: user._id }).role = role;

        useToast(useT('user.role.changed'));
      }

      return response;
    },

    async deleteUser(user) {
      const response = await useApi.delete(`/users/${user._id}`).catch(() => {
        useToast.error(useT('user.role.errorDelete'));
      });

      if (response) {
        _remove(this.users, { id: user._id });

        useToast(useT('user.deleted'));
      }

      return response;
    },

    // User/Me
    async updateUserMe(body) {
      const response = await useApi.put('/users/me', { body }).catch(() => {
        useToast.error(useT('user.edited.error'));
      });

      return response;
    },

    async updateUserMePassword(body) {
      const response = await useApi.put('/users/me/password', { body }).catch((error) => {
        if (error.response.status === 403) {
          useToast.error(useT('user.password.notSame'));
        } else {
          useToast.error(useT('common.errorSavingChanges'));
        }
      });

      return response;
    },

    // User/Invites
    async sendUserInvite(body) {
      const response = await useApi.post('/users/invite', {
        body,
      }).catch((error) => {
        if (error.response?.status === 400) {
          useToast.error(useT('user.email.alreadyExists'));
        } else {
          useToast.error(useT('mailing.sent.failed'));
        }
      });

      return response;
    },

    async sendUserInvites(body) {
      const response = await useApi.post('/users/invites', {
        body,
      }).catch(() => {
        useToast.error(useT('mailing.sent.failed'));
      });

      return response;
    },
  },
});
